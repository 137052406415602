import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactCookies from 'react-cookies';
import cookies from '../../config/cookies/cookies';
import { isWowcherBrand } from '../../helpers/pages';
import ThemeContext from '../../providers/ThemeProvider';

const TrustBox = ({ isPageDarkBackground = false, isWelcomePage = false }) => {
  const theme = useContext(ThemeContext);
  const isWowcher = isWowcherBrand();
  const backgroundColor = isPageDarkBackground
    ? theme.commoncolors.white
    : theme.colors.trustpilotbackground;

  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  const welcomePageCookie = ReactCookies.load(cookies.welcomePage) === 'd';

  if (!isWowcher) return '';

  return (
    <>
      <div
        className="trustpilot-widget"
        data-businessunit-id="4df2470a00006400050fe2d2" // We need a reference to this element to load the TrustBox in the effect.
        data-locale="en-US" // Renamed this to className.
        data-style-height={isWelcomePage ? '80px' : '20px'}
        data-style-width={
          isWelcomePage ? (welcomePageCookie ? '250px' : '100px') : '100%'
        }
        data-template-id={
          isWelcomePage
            ? '5419b732fbfb950b10de65e5'
            : '5419b6ffb0d04a076446a9af'
        }
        data-theme="light"
        ref={ref}
        style={{ pointerEvents: 'none' }}
      />
      <style jsx>{`
        .trustpilot-widget {
          background-color: ${backgroundColor};
          padding-bottom: ${isWelcomePage ? '0px' : '10px'};
          padding-top: ${isWelcomePage ? '16px' : '10px'};
          padding-left: ${welcomePageCookie ? '0px' : '40px'};
          padding-right: ${isWelcomePage ? '0px' : '40px'};
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .trustpilot-widget {
            padding-bottom: ${isPageDarkBackground
              ? '18px'
              : theme?.padding?.trustpilotbottom_mdup};
            // padding-bottom: 0;
            padding-top: 18px;
          }
        }
      `}</style>
    </>
  );
};
export default TrustBox;

TrustBox.propTypes = {
  isPageDarkBackground: PropTypes.bool,
};
